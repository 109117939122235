@import './colors.css';

.App {
  text-align: center;
  background-color: var(--color-light-grey);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-black);
  
}

h1 {
  text-align: center; 
  padding: 80px;
}


form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

input {
  padding: 0.5em;
  font-size: 1em;
  border-radius: 4px;
  border: none;
}

html {
  scroll-behavior: smooth;
}