@import './../colors.css';

.contact-container {
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    box-sizing: border-box;
    margin: 1em 0;

  }
  
  #map {
    width: 100%;
  }
  
  #map iframe {
    width: 100%;
  }
