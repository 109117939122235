/* colors.css */

:root {
    --color-blue-navy: #2b6777;
    --color-light-blue-navy: #c8d8e4;
    --color-green: #52ab98;
    --color-light-grey: #f2f2f2;
    --color-white: #ffffff;
    --color-black: #000000;
    --color-blue: #4285f4;
  }