@import './../colors.css';

.header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--color-green);
    align-items: center; /* Aligns items along the vertical line in the center */
    justify-content: center; /* Aligns items along the horizontal line in the center */
    z-index: 1000;
}

.nav_links {
    display: flex;
    justify-content: center; /* Ensures the links inside are centered */
    list-style: none;
    font-weight: bold; /* This will make the links bold */
    font-size: 20px; /* This will make the text larger. Adjust the number as needed. */
}

.nav_links li {
    margin-left: 20px;
}

.nav_links a {
    color: var(--color-white);
    text-decoration: none;
}

body {
    margin: 0;
    padding: 0;
}

