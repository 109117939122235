.carousel {
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    width: 50%;
    margin: 0 auto;
  }
  
  .carousel img {
    scroll-snap-align: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .carousel-container {
    background-color: var(--color-light-grey);
    padding: 2em 2em;
    box-sizing: border-box;
    max-width: 100%;

  }