.about {
    background-color:  var(--color-light-blue-navy);
    color:  var(--color-black);
    padding: 1em;
    margin: 1em 0;
    width: 90%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about p {
    font-size: 18px;
    /* width: 90%; */
}
