/* MovieList.css */

.movie-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 3em; 
  }
  
  .movie-list-item {
    border: 1px solid #ddd;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f8f8;
  }
  
  .movie-list-item-link {
    color: #4285f4;
    text-decoration: none;
  }
  
  .movie-list-item img {
    height: 1em;
    width: auto; 
  }
  