/* Login.css */
@import './../colors.css';

.google-btn {
    display: flex;
    align-items: center;
    background-color: var(--color-blue);
    color:  var(--color-white);
    width: 200px;
    padding: 10px 20px;
    margin-top: -1em; 
    margin-bottom: 2em;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .google-btn:hover {
    background-color: var(--color-light-blue-navy);
  }
  
  .google-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .btn-text {
    font-size: 16px;
    font-weight: bold;
  }
  
  .user-greeting {
    color:  var(--color-black);
    font-size: 1.5em;
    text-align: left;
    margin-bottom: 2em;
    margin-left: 1em;
    margin-top: -1em; 
  }