@import './../colors.css';

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  align-items: start;
  justify-items: start;
  width: 90%;
}
.generateMoviesButton {
    padding: 0.5em 1em;
    font-size: 1.1em;
    border-radius: 4px;
    border: none;
    background-color: var(--color-green);
    color: var(--color-black);
    cursor: pointer;
    margin-left: 2em; 
    margin-bottom: 4em; 
  }

.generateMoviesButton:hover {
    background-color: var(--color-blue-navy);
  }
  
  input {
    width: 70%; 
  }