/* Footer.css */
@import './../colors.css';

.footer {
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--color-green);
    color: var(--color-white);
    text-align: center;
}
